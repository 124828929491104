<template>
    
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 固定短信 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary" class="btn" :loading="loading" @click="search()">搜索</a-button>
        <!-- <a-button class="all_boder_btn" @click="toDetail(1)">新建</a-button> -->
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :rowKey="item=>item.defaultShortMessageId"
      :columns="columns"
      :loading="loading"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>

      <template slot="defaultShortMessageId" slot-scope="item">
        {{ item }}
      </template>

      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>

      <template slot="operation" slot-scope="item, row">
        <span class="blueText">
          <span @click="editFilling(row)">编辑</span>
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width="530px"
      title="文案"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">请输入文案</span>
          <div class="right_Div">
            <a-textarea
              v-model="formobj.content"
              placeholder="请输入50字以内"
              :rows="4"
              style="resize: none"
            />
          </div>
        </div>
        <span>key需要{{ formobj.keyNum }}个占位符</span>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="cancelSending()">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },

  {
    title: "场景",
    align: "center",
    dataIndex: "sceme",
  },
  {
    title: "文案",
    align: "center",
    dataIndex: "content",
  },
  // {
  //   title: "审核状态",
  //   align: "center",
  //   dataIndex: "defaultShortMessageId",
  //   scopedSlots: { customRender: "defaultShortMessageId" },
  // },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      name: "",
      columns,
      loading: false,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
      formobj: {
        content: "",
        defaultShortMsgId: "",
        keyNum: "",
      },
    };
  }, // 事件处理器
  methods: {
    editCancel(e) {
      this.edit = false;
    },
    editFilling(e) {
      // console.log(e);
      for (const key in e) {
        this.formobj[key] = e[key];
      }
      this.edit = true;
    },
    toDetail(operation, id) {
      this.$router.push(
        "/admin/MessageManage/newSMS?" +
          "userType=3" +
          "&operation=" +
          operation +
          "&id=" +
          id
      );
    },

    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/default/short/message/manage",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          keyWord: this.name,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 操作对象
    cancelSending() {
      this.$ajax({
        url: "/hxclass-management/default/short/message/manage",
        method: "PUT",
        params: {
          content: this.formobj.content,
          defaultShortMsgId: this.formobj.defaultShortMessageId,
          keyNum: this.formobj.keyNum,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.edit = false;
          this.getdataList();
        } else {
          this.$message.error(res.message);
          this.edit = false;
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {}, // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  }, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .btn {
    margin: 0 24px 14px 0;
  }
}

.all_content_box {
  textarea.ant-input {
    width: 340px;
    height: 120px;
  }
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  font-size: 18px;
  color: #333;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
}
</style>
